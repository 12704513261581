import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { PriceEdit } from "./PriceEdit";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { updateOrder, updateOrderFromObject } from "entities/orders/model";
import { getRoles } from "entities/viewer/model";
import { OrderCalc } from "entities/orders/model/calc";

const PriceRow = ({ value, title, sym, style = {} }: any) => {
  return (
    <Stack
      style={style}
      direction="row"
      justifyContent="space-between"
      marginY={1}
    >
      <div style={{ fontSize: "13px", fontWeight: "lighter" }}>{title}</div>
      <div style={{ fontSize: "13px" }}>
        {Number(value).toLocaleString("it-IT")} {sym}
      </div>
    </Stack>
  );
};

export const PriceCard = ({ order }: any) => {
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState(false);
  const roles = useAppSelector(getRoles);
  const orderCalc = new OrderCalc(order);

  const okHandler = () => {
    setEdit(false);
    dispatch(updateOrderFromObject(order));
  };

  return (
    <>
      <Paper sx={{ padding: "10px" }}>
        <PriceRow title="PREZZO LISTINO" value={order.list_price} sym="€" />

        <PriceRow title="SCONTO" value={order.discount} sym="%" />
        <PriceRow title="MARGINE" value={order.margin} sym="%" />
        <PriceRow title="TRASPORTO" value={order.trans_price} sym="€" />
        <PriceRow title="PREPARAZIONE" value={order.setup_price} sym="€" />
        <PriceRow
          title="ALLESTIMENTO ALPI"
          value={order.opt_int_price}
          sym="€"
        />
        <PriceRow
          title="ALLESTIMENTO EST."
          value={order.opt_ext_price}
          sym="€"
        />
        <PriceRow
          title="ACCANTONAMENTO"
          value={order.provision_price}
          sym="€"
        />
        <PriceRow title="ALTRE SPESE" value={order.other_price} sym="€" />
        <PriceRow title="SVALUTAZIONE USATO" value={order.devaluation} sym="€" />
        <PriceRow
          title="MARGINE ALLEST EST."
          value={order.opt_ext_margin}
          sym="%"
        />
        <PriceRow
          style={{ color: "darkgreen" }}
          title="COSTO VEICOLO"
          value={orderCalc.calcCost()}
          sym="€"
        />
        {(roles?.includes("stock_manager") ||
          roles?.includes("price_manager")) && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              sx={{ alignContent: "right" }}
              variant="outlined"
              size="small"
              onClick={() => setEdit(true)}
            >
              MODIFICA
            </Button>
          </div>
        )}
      </Paper>

      <Dialog open={edit}>
        <DialogContent>
          <PriceEdit order={order} />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={() => setEdit(false)}>
            INDIETRO
          </Button>
          <Button variant="outlined" size="small" onClick={() => okHandler()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
