import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["orders", "order"],
  endpoints: (builder) => ({
    getStockOrders: builder.query({
      query: () => "order/get-orders/?status=in stock",
      providesTags: ["orders"],
    }),
    getSoldOrders: builder.query({
      query: () => "order/get-orders/?status=venduto",
      providesTags: ["orders"],
    }),
    getUsed: builder.query({
      query: () => "order/get-orders/?model=UST",
      providesTags: ["orders"],
    }),
    getSearchOrders: builder.query({
      query: (str: string) => `order/search/?search=${str}`,
      providesTags: ["orders"],
    }),
    getOrderById: builder.query({
      query: (id) => `${id}`,
      providesTags: ["order"],
    }),
    // addVehicle: builder.mutation({
    //   query: (contact) => ({
    //     url: "",
    //     method: "POST",
    //     body: contact,
    //   }),
    // }),
    // updateVehicle: builder.mutation({
    //   query: (vehicle: Vehicle) => ({
    //     url: `${vehicle.id}/`,
    //     method: "PUT",
    //     body: vehicle,
    //   }),
    //   invalidatesTags: ["Vehicles", "Vehicle"],
    // }),
    // removeUser: builder.mutation({
    //   query: (id) => ({
    //     url: id,
    //     method: "DELETE",
    //   }),
    // }),
  }),
});

export const {
  useGetStockOrdersQuery,
  useGetSoldOrdersQuery,
  useGetSearchOrdersQuery,
  useGetOrderByIdQuery,
  useGetUsedQuery,
} = ordersApi;
