import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

import orderReducer from "entities/orders/model/orderSlice";
import viewerReducer from "entities/viewer/model/viewerSlice";
import fileReducer from "entities/files/model";
import offersReducer from "entities/offers/model";
import managersReducer from "entities/managers/model";
import commentsReducer from "entities/comments/model";
import actionsReducer from "entities/actions/model/actionsSlice";
import filtersReducer from "entities/filters/model/filterSlice";
import tasksReducer from "entities/tasks/model/tasksSlice";
import publicsReducer from "entities/publics/model/publicsSlice";
import waReducer from "entities/whatsapp/model/waSlice";
import leadsReducer from "entities/crm/leads/model/leadsSlice";
import contactsReducer from "entities/crm/contacts/model/contactsSlice";
import companiesReducer from "entities/crm/companies/model/companiesSlice";
import servicesReducer from "entities/services/model/servicesSlice";

import waMiddleware from "./middleware/wapp";
import invoiceReducer from "entities/invoices/model/invoiceSlice";
import messagesReducer from "entities/messages/model/messagesSlice";
import quoteReducer from "entities/quotes/model/quoteSlice";
import vehiclesReducer from "entities/vehicles/api/vehiclesSlice";

import { crmApi } from "entities/crm/leads/model/qapi";
import { invoiceApi } from "entities/invoices/model/api";
import { extdataApi } from "entities/extdata/model/api";
import { contactsApi } from "entities/crm/contacts/model/rtkapi";
import { companiesApi } from "entities/crm/companies/model/rtkapi";
import { vehiclesApi } from "entities/vehicles/api/api";
import { ordersApi } from "entities/orders/model/rtk-api";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    savePreviousLocations: 1,
  });

export const store = configureStore({
  reducer: {
    router: routerReducer,
    orders: orderReducer,
    filters: filtersReducer,
    viewer: viewerReducer,
    files: fileReducer,
    offers: offersReducer,
    managers: managersReducer,
    comments: commentsReducer,
    actions: actionsReducer,
    tasks: tasksReducer,
    publics: publicsReducer,
    leads: leadsReducer,
    wasocket: waReducer,
    contacts: contactsReducer,
    companies: companiesReducer,
    invoices: invoiceReducer,
    messages: messagesReducer,
    quotes: quoteReducer,
    services: servicesReducer,
    vehicles: vehiclesReducer,
    [crmApi.reducerPath]: crmApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [extdataApi.reducerPath]: extdataApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [vehiclesApi.reducerPath]: vehiclesApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      // waMiddleware,
      routerMiddleware,
      crmApi.middleware,
      invoiceApi.middleware,
      extdataApi.middleware,
      contactsApi.middleware,
      companiesApi.middleware,
      vehiclesApi.middleware,
      ordersApi.middleware,
      //waMiddleware,
    ]),
});

export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
