export const CalendarPage = () => {
  return (
    <iframe
      src="https://alpiapp.rdas.site/calendar"
      title="Embedded React App"
      width="100%"
      height="700px"
      style={{ border: "none" }}
    ></iframe>
  );
};
