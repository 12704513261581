import { green, red } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";

export const lightTheme: Theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },  
  },
});

export const manTheme: Theme = createTheme({
  palette: {
    primary: {
      main: '#303c49',
    },
  },
});
