import { Divider } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { useGetUsedQuery } from "entities/orders/model/rtk-api";
import { Orders } from "entities/orders/ui/Orders";
import { getRoles } from "entities/viewer/model";
import { Link, useParams } from "react-router-dom";

export const VehiclesPage = () => {
  const { type } = useParams();
  const { data: used } = useGetUsedQuery(undefined, { skip: type !== "used" });
  console.log(used)

  return (
    <>

        <div>
          <div className="flex gap-3 text-sm my-2 font-bold">
            <div className="">
              <Link to="/vehicles/man">MAN</Link>
            </div>
            <div className="">
              <Link to="/vehicles/used">USATI</Link>
            </div>
            <div className="">
              <Link to="/vehicles/sem">SEMIRIMORCHI</Link>
            </div>
          </div>
          <Divider />
        </div>
      {type ? <h1>{type.toUpperCase()}</h1> :  <div>NONE</div>}
    </>
  );
};
