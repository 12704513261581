import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Order } from "types/order";
import { OrderInlineEdit } from "../OrderInlineEdit";

interface OrderPriceEditProps {
  order: Order;
}

export const PriceEdit = ({ order }: OrderPriceEditProps) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="table">
          <TableBody>
            <TableRow>
              <TableCell>Nome:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="name" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Prezzo listino, €:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="list_price" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Sconto, %:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="discount" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Margine, %:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="margin" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Trasporto, €:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="trans_price" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Preparazione, €:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="setup_price" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Allestimento Alpi, €:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="opt_int_price" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Accantonamento, €:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="provision_price" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Allestimento est., €:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="opt_ext_price" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Altre spese, €:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="other_price" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Svalutazione usato, €:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="devaluation" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Margine allest. est., %:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="opt_ext_margin" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Prezzo vendita, €:</TableCell>
              <TableCell align="right">
                <OrderInlineEdit order={order} field="sale_price" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
