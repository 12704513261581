import { Button, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import Loader from "shared/ui/loader/loader";
import { getOffers, getOffersList, offersState } from "../model";
import { OfferDetails } from "./OfferDetails";

import { getCurrentOrder } from "entities/orders/model";
import "./styles.css";
import { OfferCard } from "./OfferCard";
import { useLocation } from "react-router-dom";
import { NewOffer } from "./NewOffer";
import { getRoles } from "entities/viewer/model";
import { Calculator } from "entities/offers/ui/Calculator";

// offers of order

export const Offers = () => {
  const [newOffer, setNewOffer] = useState(false);

  const dispatch = useAppDispatch();
  const roles = useAppSelector(getRoles);
  const { loading, current } = useAppSelector(offersState);
  const list = useAppSelector(getOffersList);
  const currentUrl = useLocation().pathname;
  const order = useAppSelector(getCurrentOrder);

  useEffect(() => {
    dispatch(getOffers("", order.id));
  }, []);

  if (loading) return <Loader />;

  if (current) return <OfferDetails />;

  return (
    <>
      <Stack direction="column" spacing={1}>
        {list.map((offer) => (
          <OfferCard key={offer.id} offer={offer} />
        ))}
        {order.status === "in stock" && roles?.includes("seller") && (
          <Calculator order={order} />
        )}
      </Stack>

      <NewOffer open={newOffer} setIsOpen={setNewOffer} />
    </>
  );
};
