import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import "../../leads/ui/lead.modules.css";
import { useEffect, useState } from "react";
import { Contact } from "entities/crm/contacts/model/types";
import { TextField } from "@mui/material";
import { useGetCompaniesQuery } from "entities/crm/companies/model/rtkapi";
import { useGetContactsQuery } from "../model/rtkapi";

interface ContactSearchProps {
  setValue: (value: string, field: string) => void;
  setContact: (contact: Contact) => void;
  contact: Contact | null;
  field: string;
  label: string;
  value: string;
  required?: boolean;
  helperText?: string | null;
}

export const ContactSearch = ({
  contact,
  setValue,
  setContact,
  field,
  label,
  value,
  required = false,
  helperText = null,
}: ContactSearchProps) => {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);

  // const contacts = useAppSelector(
  //   (state: RootState) => state.contacts.entities
  // );
  const { data: contacts_list } = useGetContactsQuery(undefined);
  //const contacts_list = Object.values(contacts);

  const [filtered, setFiltered] = useState<Contact[]>(contacts_list);

  useEffect(() => {
    if (contacts_list)
      setFiltered(
        contacts_list.filter((item: any) => {
          return (
            search === "" ||
            (item.name &&
              item.name.toLowerCase().includes(search.toLowerCase())) ||
            (item.email &&
              item.email.toLowerCase().includes(search.toLowerCase())) ||
            (item.phone && item.phone.includes(search.toLowerCase()))
          );
        })
      );
  }, [search]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setValue(field, event.target.value);
  };

  const onBlurHandler = () => {
    setTimeout(() => setSearched(false), 100);
  };

  const contactSelectHandler = (item: Contact) => {
    console.log(item);
    setContact(item);
    setSearched(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <TextField
        size="small"
        fullWidth
        value={value}
        type="search"
        id={field}
        label={label}
        helperText={helperText}
        autoComplete="new-password"
        required={required}
        onChange={handleChange}
        onFocus={(event) => {
          setSearched(true);
          event.target.setAttribute("autocomplete", "off");
        }}

        //onBlur={() => onBlurHandler()}
      />

      {search.length > 0 && searched && (
        <div
          style={{
            border: "1px solid lightgrey",
            //position: "absolute",
            width: "max-content",
            zIndex: "20",
            backgroundColor: "white",
          }}
        >
          {filtered &&
            filtered.slice(-5).map((item) => (
              <div
                key={item.id}
                style={{
                  color: "black",
                  cursor: "pointer",
                  padding: "8px",
                  textAlign: "left",
                  fontSize: "12px",
                }}
                onClick={() => contactSelectHandler(item)}
              >
                {item.name} {item.phone} {item.email}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
