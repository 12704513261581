import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { history } from "./app/store";
import App from "./App";
import "./index.css";
import { ThemeProvider } from "@mui/material";
import { lightTheme, manTheme } from "themes";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider theme={manTheme}>
      <div>
        <App />
      </div>
      </ThemeProvider>
    </Router>
  </Provider>
  //</React.StrictMode>
);
