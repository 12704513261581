import "../../leads/ui/lead.modules.css";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useGetCompaniesQuery } from "entities/crm/companies/model/rtkapi";
import { Company } from "../model/types";

interface CompanySearchProps {
  setValue: (value: string, field: string) => void;
  setCompany: (company: Company) => void;
  company: Company | null;
  field: string;
  label: string;
  value: string;
  required?: boolean;
  helperText?: string | null;
}

export const CompanySearch = ({
  company,
  setValue,
  setCompany,
  field,
  label,
  value,
  required = false,
  helperText = null,
}: CompanySearchProps) => {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);

  // const contacts = useAppSelector(
  //   (state: RootState) => state.contacts.entities
  // );
  const { data: company_list } = useGetCompaniesQuery(undefined);
  //const contacts_list = Object.values(contacts);

  const [filtered, setFiltered] = useState<Company[]>(company_list);

  useEffect(() => {
    if (company_list)
      setFiltered(
        company_list.filter((item: any) => {
          return (
            search === "" ||
            (item.name &&
              item.name.toLowerCase().includes(search.toLowerCase()))
          );
        })
      );
  }, [search]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setValue(field, event.target.value);
  };

  const companySelectHandler = (item: Company) => {
    setCompany(item);
    setSearched(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <TextField
        size="small"
        fullWidth
        value={value}
        type="search"
        id={`company_${field}`}
        label={label}
        required={required}
        autoComplete="new-password"
        onChange={handleChange}
        onFocus={(event) => {
          setSearched(true);
          event.target.setAttribute("autocomplete", "off");
        }}
      />

      {search.length > 0 && searched && (
        <div
          style={{
            border: "1px solid lightgrey",
            position: "absolute",
            width: "max-content",
            zIndex: "20",
            backgroundColor: "white",
          }}
        >
          {filtered &&
            filtered.slice(-5).map((item) => (
              <div
                key={item.id}
                style={{
                  color: "grey",
                  cursor: "pointer",
                  padding: "8px",
                  textAlign: "left",
                  fontSize: "12px",
                }}
                onClick={() => companySelectHandler(item)}
              >
                {item.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
