export const arrToMap = (arr: any) => {
  if (!Array.isArray(arr)) {
    arr = Array.of(arr);
  }
  return arr.reduce((acc: any, item: any) => ({ ...acc, [item.id]: item }), {});
};

export const formatPhone = (phone: string) => {
  const phoneNumberRegex = /^(\+)$/;
  const formatted_phone = !phoneNumberRegex.test(phone) ? phone : phone;
  return formatted_phone;
};


export function isEmail(email: string): boolean {
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
}

export function isPhoneNumber(phone: string): boolean {
  const phoneRegex = /^(\+?\d{1,3})?[-.\s]?(\(?\d{2,3}\)?)?[-.\s]?\d{3}[-.\s]?\d{2,4}[-.\s]?\d{2,4}$/;
  return phoneRegex.test(phone);
}