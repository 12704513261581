import { Order } from "types/order";
import { OrderList } from "./OrderList";
import { useState } from "react";

interface Props {
  orders: Order[];
}

export const Orders = ({ orders }: Props) => {

  return (
    <>
      <OrderList list={orders} />
    </>
  );
};
