import { Divider } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { useGetUsedQuery } from "entities/orders/model/rtk-api";
import { Orders } from "entities/orders/ui/Orders";
import { getRoles } from "entities/viewer/model";
import { Link, useParams } from "react-router-dom";

export const OrdersPage = () => {

  return (
    <>
      <Orders />
    </>
  );
};
